<template>
  <div v-if="!commentPrivate || canShowPrivateComment()">
    <div class="mb-4">
      <h3 class="text-xl font-semibold flex justify-content-between">
        <span>Bình luận ({{ comments.paginatorInfo.total }})</span>
        <span
          class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none rounded-full text-white bg-red-500"
          v-if="commentPrivate === 1"
          >Bình luận riêng tư</span
        >
      </h3>
    </div>

    <div class="comment-container" v-if="allowComment !== false">
      <editor v-model:content="content" />
      <div class="flex justify-content-end">
        <div class="flex align-items-center mt-2">
          <span
            v-if="disabled"
            :uk-tooltip="
              'title: Chức năng bình luận của bạn bị hạn chế. Chức năng bị hạn chế đến ' +
              getAllowCommentTime(user)
            "
            ><i class="fa fa-exclamation-circle"></i
          ></span>
          <button
            class="ml-1 basic-btn text-white"
            :class="{
              'hover:bg-green-700': !disabled,
              'cursor-not-allowed': disabled,
              'bg-green-600': !disabled,
              'bg-blue-300': disabled,
            }"
            v-on:click="addComment"
            :disabled="!content.length || disabled"
          >
            Thêm bình luận
          </button>
        </div>
      </div>
    </div>

    <div v-if="allowComment === false" class="card">
      <div class="card-body">
        Chức năng bình luận đang tạm khóa. Bạn không thể gửi bình luận.
      </div>
    </div>

    <div
      class="flex justify-between items-center mt-6"
      id="comments"
      v-if="comments && comments.data && comments.data.length"
    >
      <select
        class="w-auto px-2"
        v-model="sort"
        v-if="comments.paginatorInfo.total"
      >
        <option value="latest">Tất cả bình luận</option>
        <option value="like">Bình luận hay nhất</option>
        <option value="specific">Bình luận đang lọc</option>
      </select>
      <v-pagination
        class="justify-content-center"
        v-if="comments.paginatorInfo.lastPage > 1"
        v-model="comments.paginatorInfo.currentPage"
        :pages="comments.paginatorInfo.lastPage"
        :range-size="1"
        active-color="#FFF"
        @update:modelValue="loadComments"
      />
      <div
        v-if="sort === 'specific' && commentId && comments.paginatorInfo.total"
      >
        Bạn đang ở chế độ lọc bình luận duy nhất
      </div>
    </div>

    <div
      class="card p-3 mt-5"
      uk-lightbox="toggle:.lightbox"
      v-if="comments.data && comments.data.length"
    >
      <div
        :id="'comment-' + comment.id"
        class="mb-5 flex"
        v-for="comment in comments.data"
        :key="'comment' + comment.id"
      >
        <template v-if="!comment.is_private || canShowPrivateComment()">
          <div class="flex text-center flex-col space-y-2">
            <div class="w-10 h-10 rounded-full relative flex-shrink-0">
              <router-link
                :to="{ name: 'UserDetail', params: { id: comment.user.id } }"
                v-if="comment.user"
              >
                <img
                  :src="
                    comment.user && comment.user.avatar
                      ? comment.user.avatar.url
                      : 'https://static.bcdcnt.net/assets/images/avatars/avatar-4.jpg'
                  "
                  alt=""
                  class="absolute h-full rounded-full w-full object-cover"
                />
              </router-link>
              <img
                src="https://static.bcdcnt.net/assets/images/avatars/avatar-4.jpg"
                alt=""
                class="absolute h-full rounded-full w-full"
                v-if="!comment.user"
              />
            </div>
          </div>
          <div class="w-full">
            <div
              class="text-gray-700 py-2 px-3 rounded-3xl bg-gray-100 relative lg:ml-5 ml-2 dark:bg-gray-800 dark:text-gray-100"
            >
              <h4
                class="text-base m-0 font-semibold flex items-center justify-content-between"
              >
                <template class="flex justify-content-between">
                  <span class="flex items-center">
                    <router-link
                      :to="{
                        name: 'UserDetail',
                        params: { id: comment.user.id },
                      }"
                      class="mr-1"
                      v-if="comment.user && comment.user.username"
                    >
                      {{ comment.user.username }}
                    </router-link>
                    <span v-if="!comment.user"> {{ comment.nickname }}</span>
                    <template
                      v-if="
                        comment.user &&
                        comment.user.roles &&
                        comment.user.roles.length
                      "
                    >
                      <span
                        v-for="(role, index) in comment.user.roles"
                        :key="'role' + index"
                        :class="
                          'mr-2 inline-flex items-center justify-center px-2 py-1 text-xs leading-none rounded-full text-white ' +
                          getRoleClass(role.alias)
                        "
                        >{{ role.name }}</span
                      >
                    </template>
                  </span>
                  <span
                    class="mr-2 inline-flex items-center justify-center px-2 py-1 text-xs leading-none rounded-full text-white bg-red-500"
                    v-if="comment.is_private"
                    >Riêng tư</span
                  >
                </template>
                <!-- <templte v-if="comment.user && comment.user.gifts && comment.user.gifts.length">
                  <img :src="gift.product.icon" :uk-tooltip="'title: ' + gift.post_title" width="15" :key="'gift' + gift.ID" v-for="(gift) in comment.user.gifts"/>
                </templte> -->
              </h4>

              <show-more
                v-if="!comment.is_editing && showMore !== false"
                :content="comment.content"
                :length="100"
                class=""
              ></show-more>
              <div
                v-if="!comment.is_editing && showMore === false"
                class="content mt-2"
                v-html="comment.content"
              ></div>

              <div class="comment-container" v-if="comment.is_editing">
                <editor v-model:content="currentComment.content" />
                <div class="flex justify-content-end">
                  <button
                    class="cancel-comment-btn mt-2 mr-2"
                    v-on:click="cancelEditingComment(comment)"
                  >
                    Hủy
                  </button>
                  <button
                    class="comment-btn mt-2"
                    v-on:click="updateComment"
                    :disabled="currentComment.content.length === 0"
                  >
                    Cập nhật bình luận
                  </button>
                </div>
              </div>

              <div
                class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"
              ></div>
            </div>
            <div class="justify-between flex mt-2">
              <div class="text-xs flex items-center space-x-3 ml-5">
                <span class="flex items-center">
                  <a
                    href="javascript:void(0)"
                    v-on:click="loveOrUnlove(comment)"
                    :uk-tooltip="'title: ' + getUserLoves(comment)"
                    class="text-red-600 mr-1"
                  >
                    <i class="far fa-heart" v-if="!comment.hasLoved"></i>
                    <i
                      class="fas fa-heart text-red-600"
                      v-if="comment.hasLoved"
                    ></i>
                  </a>
                  {{
                    comment.loves && comment.loves.paginatorInfo.total > 0
                      ? comment.loves.paginatorInfo.total
                      : ""
                  }}
                </span>
                <span>{{ timeago(comment.created_at) }}</span>
                <span>
                  <i
                    :uk-tooltip="
                      comment.markRequestUser
                        ? 'title: Được đánh dấu là yêu cầu bởi ' +
                          comment.markRequestUser.username
                        : 'title: Được đánh dấu là yêu cầu'
                    "
                    class="fa fa-hand-paper"
                    aria-hidden="true"
                    v-if="comment.is_request && !comment.request_status"
                  ></i>
                  <i
                    :uk-tooltip="
                      comment.processRequestUser
                        ? 'title: Yêu cầu không thể xử lý bởi ' +
                          comment.processRequestUser.username
                        : 'title: Yêu cầu không thể xử lý'
                    "
                    class="fa fa-hand-paper text-red-600"
                    aria-hidden="true"
                    v-if="
                      comment.is_request &&
                      comment.request_status === 'rejected'
                    "
                  ></i>
                  <i
                    uk-tooltip="title: Cần cung cấp thêm thông tin"
                    class="fa fa-hand-paper text-blue-600"
                    aria-hidden="true"
                    v-if="
                      comment.is_request &&
                      comment.request_status === 'need_more_info'
                    "
                  ></i>
                  <i
                    :uk-tooltip="
                      comment.processRequestUser
                        ? 'title: Yêu cầu đã được xử lý bởi ' +
                          comment.processRequestUser.username
                        : 'title: Yêu cầu đã được xử lý'
                    "
                    class="fa fa-hand-paper text-green-600"
                    aria-hidden="true"
                    v-if="comment.request_status === 'done'"
                  ></i>
                </span>
              </div>

              <div v-if="this.user">
                <a href="#">
                  <i
                    class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                  ></i>
                </a>
                <div
                  class="bg-white w-62 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                  uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small"
                >
                  <ul class="space-y-1">
                    <li>
                      <a
                        href="javascript:void(0)"
                        @click="markAsRequest(comment)"
                        v-if="canMarkAsRequest(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="fa fa-hand-paper mr-1"></i> Đánh dấu là yêu
                        cầu
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="unmarkRequest(comment)"
                        v-if="comment.is_request && canMarkAsRequest(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="far fa-hand-paper mr-1"></i> Bỏ đánh dấu là
                        yêu cầu
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="changeRequestStatus(comment, 'done')"
                        v-if="canChangeRequestStatus() || canManageThumbnail()"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="fa fa-hand-paper text-green-600 mr-1"></i> Đã
                        xử lý xong yêu cầu
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="changeRequestStatus(comment, 'rejected')"
                        v-if="canChangeRequestStatus()"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="fa fa-hand-paper text-red-600 mr-1"></i> Không
                        thể xử lý yêu cầu
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="
                          changeRequestStatus(comment, 'need_more_info')
                        "
                        v-if="canChangeRequestStatus()"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="fa fa-hand-paper text-blue-600 mr-1"></i> Cần
                        cung cấp thêm thông tin
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="editComment(comment)"
                        v-if="canEditComment(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="uil-edit-alt mr-1"></i> Sửa bình luận
                      </a>
                    </li>
                    <li v-if="showCreateSheet && canManageSheet()">
                      <a
                        href="javascript:void(0)"
                        v-on:click="createSheetFromComment(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="uil-plus mr-1"></i> Tạo sheet nhạc
                      </a>
                    </li>
                    <li v-if="isAdmin()">
                      <a
                        href="javascript:void(0)"
                        v-on:click="replaceFileFromComment(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="uil-music mr-1"></i> Thay thế bản thu
                      </a>
                    </li>
                    <li v-if="canManageThumbnail()">
                      <a
                        href="#"
                        uk-toggle="target: #replaceImageModal"
                        v-on:click="setCurrentComment(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="uil-image mr-1"></i> Thay thế ảnh minh
                        họa/avatar
                      </a>
                    </li>
                    <li v-if="isAdmin()">
                      <a
                        href="#"
                        uk-toggle="target: #moveCommentsModal"
                        v-on:click="setCurrentComment(comment)"
                        class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                      >
                        <i class="uil-comment mr-1"></i> Chuyển bình luận
                      </a>
                    </li>
                    <li>
                      <hr class="-mx-2 my-2 dark:border-gray-800" />
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="deleteComment(comment)"
                        v-if="comment.canDelete"
                        class="uk-drop-close flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                      >
                        <i class="uil-trash-alt mr-1"></i> Xóa bình luận
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        v-on:click="hideComment(comment)"
                        v-if="isAdmin"
                        class="uk-drop-close flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                      >
                        <i class="uil-eye-slash mr-1"></i> Ẩn bình luận
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <v-pagination
        class="justify-content-center"
        v-if="comments.paginatorInfo.lastPage > 1"
        v-model="comments.paginatorInfo.currentPage"
        :pages="comments.paginatorInfo.lastPage"
        :range-size="1"
        active-color="#FFF"
        @update:modelValue="prepareLoadComments"
      />
    </div>

    <alert :message="message" />

    <div id="replaceImageModal" class="create-post" uk-modal>
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
      >
        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Thay thế ảnh minh họa/avatar</h3>
          <button
            class="replaceImageClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
            type="button"
            uk-close
            uk-tooltip="title: Close ; pos: bottom ;offset:7"
          ></button>
        </div>

        <div class="px-5 py-2">
          <label for="title" class="font-semibold">Vị trí ảnh muốn thay?</label>
          <input
            v-model="replaceImageIndex"
            class="shadow-none with-border"
            id="title"
            type="number"
            autocomplete="off"
            required
          />
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a
            href="javascript:void(0)"
            v-on:click="replaceImageFromComment()"
            class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
          >
            Cập nhật</a
          >
        </div>
      </div>
    </div>

    <div id="moveCommentsModal" class="create-post" uk-modal>
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
      >
        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Chuyển bình luận</h3>
          <button
            class="moveCommentsClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
            type="button"
            uk-close
            uk-tooltip="title: Close ; pos: bottom ;offset:7"
          ></button>
        </div>

        <div class="px-5 py-2">
          <label for="link" class="font-semibold">Link muốn chuyển tới</label>
          <input
            v-model="moveCommentLink"
            class="shadow-none with-border"
            id="link"
            type="text"
            autocomplete="off"
            required
          />
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a
            href="javascript:void(0)"
            v-on:click="moveComments()"
            class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
          >
          Di chuyển</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style>
:root {
  --ck-border-radius: 10px;
}

.ck-toolbar_grouping {
  width: 100% !important;
}
</style>

<script>
import ApiService from "../../core/services/api.service";
import {
  censorBadWords,
  convertOembed,
  getRoleClass,
  getUserLoves,
  hasLoved,
  isAdmin,
  lightBoxable,
  isAdminOfEditor,
  canManageComment,
  makePlayTime,
  showAlert,
  convertDate,
  timeago,
  canManageSheet,
  canManageSong,
  canManagePeople,
  canManageThumbnail,
} from "../../core/services/utils.service";
import ShowMore from "../ShowMore";
import Editor from "../Editor";
import { getUser } from "../../core/services/jwt.service";
import Alert from "../Alert";
import moment from "moment";
import "autolink-js";

export default {
  name: "Comment",
  props: {
    objectType: String,
    objectId: String,
    objectUserId: String,
    field: String,
    type: String,
    slug: String,
    showMore: Boolean,
    commentPrivate: Number,
    allowComment: {
      type: Boolean,
      default: true,
    },
    commentId: String,
    showCreateSheet: Boolean,
  },
  components: {
    Alert,
    ShowMore,
    Editor,
  },
  data() {
    return {
      message: "",
      content: "",
      comments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      },
      currentCommentId: null,
      hasGotoCurrentComment: false,
      needGotoCommentList: false,
      currentComment: {
        id: null,
        content: "",
      },
      user: null,
      sort: "latest",
      replaceImageIndex: 1,
      moveCommentLink: "",
    };
  },
  methods: {
    loadComments() {
      if (!this.objectId) {
        return;
      }

      let whereDefault = { AND: [{ column: "status", value: 1 }] };
      let where = JSON.parse(JSON.stringify(whereDefault));
      let orderBy = [{ column: "id", order: "DESC" }];

      if (this.commentId && this.sort === "specific") {
        where.AND.push({ column: "id", value: this.commentId });
      }

      if (this.sort === "like") {
        orderBy = [{ column: "likes", order: "DESC" }];
      }

      if (this.sort === "latest") {
        orderBy = [{ column: "id", order: "DESC" }];
      }

      let query = `query($id: ${this.type}!, $page: Int, $orderBy: [OrderByClause!], $where: WhereConditions) {
        ${this.objectType}(${this.field}: $id) {
          comments(first: 10, page: $page, orderBy: $orderBy, where: $where) {
            data {
              id
              content
              created_at
              nickname
              is_request
              is_private
              request_status
              user {
                username
                id
                profile_id
                prevent_delete_comment
                avatar {
                  url
                }
                roles(where: {AND: [{column: "is_primary", value: 1}, {column: "display_in_comment", value: 1}]}) {
                  name
                  alias
                  group_type
                  userRolePivot {
                    role
                    custom_title
                  }
                }
                gifts
              }
              markRequestUser {
                username
                id
              }
              processRequestUser {
                username
                id
              }
              loves(first: 100) {
                data {
                  user_id
                  user {
                    id
                    username
                  }
                }
                paginatorInfo {
                  currentPage
                  lastPage
                  total
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {
        id: this.slug ? this.slug : this.objectId,
        page: this.comments.paginatorInfo.currentPage,
        orderBy: orderBy,
        where: where,
      })
        .then(({ data }) => {
          if (
            data.data &&
            data.data[this.objectType] &&
            data.data[this.objectType].comments
          ) {
            this.comments = data.data[this.objectType].comments;
            if (this.comments.data && this.comments.data.length) {
              this.comments.data.map((comment) => {
                comment.hasLoved = this.hasLoved(comment);
                comment.content = lightBoxable(
                  makePlayTime(censorBadWords(comment.content))
                ).autoLink();
                comment.canDelete = this.canDelete(comment);
                setTimeout(() => {
                  comment.content = convertOembed(comment.content);
                }, 0);
                if (comment.user && comment.user.gifts) {
                  comment.user.gifts = JSON.parse(comment.user.gifts);
                }
              });
            }
            setTimeout(() => {
              this.gotoCurrentComment();
            }, 0);

            if (this.needGotoCommentList) {
              this.gotoCommentList();
            }
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    editComment(comment) {
      comment.is_editing = true;
      this.setCurrentComment(comment);
    },
    cancelEditingComment(comment) {
      comment.is_editing = false;
    },
    setCurrentComment(comment) {
      this.currentComment = JSON.parse(JSON.stringify(comment));
    },
    addComment() {
      let query = `mutation($content: String!, $type: String!, $object_id: Int!) {
        addComment(content: $content, type: $type, object_id: $object_id) {
          id
          status
        }
      }`;

      ApiService.graphql(query, {
        content: this.content,
        type: this.objectType,
        object_id: parseInt(this.objectId),
      })
        .then(({ data }) => {
          if (data.data && data.data.addComment) {
            this.content = "";
            if (data.data.addComment.status === 2) {
              this.message =
                "Bình luận của bạn sẽ được BBT kiểm duyệt trước khi xuất hiện trên trang nhạc.";
              showAlert();
            }
            this.loadComments();
          } else {
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.message = data.errors[0].message;
              showAlert();
            }
          }
        })
        .catch((response) => {
          this.message = response.message;
          showAlert();
        });
    },
    updateComment() {
      let query = `mutation($content: String!, $id: ID!) {
        updateComment(content: $content, id: $id) {
          id
          status
        }
      }`;

      ApiService.graphql(query, {
        content: this.currentComment.content,
        id: this.currentComment.id,
      })
        .then(({ data }) => {
          if (data.data && data.data.updateComment) {
            if (data.data.updateComment.status === 2) {
              this.message =
                "Bình luận của bạn sẽ được BBT kiểm duyệt trước khi xuất hiện trên trang nhạc.";
              showAlert();
            }

            this.loadComments();
          } else {
            this.message = data.errors[0].message;
            showAlert();
          }
        })
        .catch((response) => {
          this.message = response.message;
          showAlert();
        });
    },
    deleteComment(comment) {
      let r = confirm("Bạn có chắc chắn muốn xóa bình luận này?");
      if (r === true) {
        let query = `mutation($id: ID!) {
        deleteComment(id: $id)
      }`;

        ApiService.graphql(query, { id: comment.id })
          .then(({ data }) => {
            if (data.data && data.data.deleteComment) {
              this.loadComments();
            } else {
              this.message = data.errors[0].message;
              showAlert();
            }
          })
          .catch((response) => {
            this.message = response.message;
            showAlert();
          });
      }
    },
    hideComment(comment) {
      let r = confirm("Bạn có chắc chắn muốn ẩn bình luận này khỏi trang chủ?");
      if (r === true) {
        let query = `mutation($id: ID!) {
        hideComment(id: $id)
      }`;

        ApiService.graphql(query, { id: comment.id })
          .then(({ data }) => {
            if (data.data && data.data.hideComment) {
              this.message = "Ẩn thành công";
              showAlert();
            } else {
              this.message = data.errors[0].message;
              showAlert();
            }
          })
          .catch((response) => {
            this.message = response.message;
            showAlert();
          });
      }
    },
    loveOrUnlove(comment) {
      if (comment.hasLoved) {
        this.unlove(comment);
      } else {
        this.love(comment);
      }
    },
    love(comment) {
      let query = `mutation($id: ID!) {
        love(lovable_id: $id, lovable_type: "comment")
      }`;

      ApiService.graphql(query, { id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.love) {
            this.loadComments();
          } else {
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    unlove(comment) {
      let query = `mutation($id: ID!) {
        unlove(lovable_id: $id, lovable_type: "comment")
      }`;

      ApiService.graphql(query, { id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.unlove) {
            this.loadComments();
          } else {
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    markAsRequest(comment) {
      if (!this.canMarkAsRequest(comment)) {
        return;
      }

      let query = `mutation($id: ID!) {
        markAsCommentRequest(id: $id) {
          id
          markRequestUser {
            id
            username
          }
        }
      }`;

      ApiService.graphql(query, { id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.markAsCommentRequest) {
            console.log(
              "data.data.markAsCommentRequest",
              data.data.markAsCommentRequest
            );
            comment.is_request = 1;
            comment.markRequestUser =
              data.data.markAsCommentRequest.markRequestUser;
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    unmarkRequest(comment) {
      if (!this.canMarkAsRequest(comment)) {
        return;
      }

      let query = `mutation($id: ID!) {
        unmarkCommentRequest(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, { id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.unmarkCommentRequest) {
            comment.is_request = 0;
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    changeRequestStatus(comment, status) {
      let query = `mutation($id: ID!, $status: String!) {
        changeCommentRequestStatus(id: $id, status: $status) {
          id
          processRequestUser {
            id
            username
          }
        }
      }`;

      ApiService.graphql(query, { id: comment.id, status: status })
        .then(({ data }) => {
          if (data.data && data.data.changeCommentRequestStatus) {
            comment.request_status = status;
            comment.is_request = 1;
            comment.processRequestUser =
              data.data.changeCommentRequestStatus.processRequestUser;
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    createSheetFromComment(comment) {
      let query = `mutation($comment_id: ID!) {
        createSheetFromComment(comment_id: $comment_id) {
          id
        }
      }`;

      ApiService.graphql(query, { comment_id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.createSheetFromComment) {
            this.$toast.success("Tạo sheet nhạc thành công");
            this.$emit("createSheetFromCommentDone");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    replaceFileFromComment(comment) {
      let query = `mutation($comment_id: ID!) {
        replaceFileFromComment(comment_id: $comment_id) {
          id
        }
      }`;

      ApiService.graphql(query, { comment_id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.replaceFileFromComment) {
            this.$toast.success("Thay thế bản thu thành công");
            this.$emit("replaceFileFromCommentDone");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    replaceImageFromComment() {
      let query = `mutation($comment_id: ID!, $index: Int) {
        replaceImageFromComment(comment_id: $comment_id, index: $index) {
          id
        }
      }`;

      ApiService.graphql(query, {
        comment_id: this.currentComment.id,
        index: parseInt(this.replaceImageIndex),
      })
        .then(({ data }) => {
          if (data.data && data.data.replaceImageFromComment) {
            this.$toast.success("Thay thế ảnh minh họa/đại diện thành công");
            this.$emit("replaceImageFromCommentDone");
            window.$(".replaceImageClose").click();
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    moveComments() {
      let query = `mutation($link: String!, $comment_ids: [ID]!) {
        moveComments(link: $link, comment_ids: $comment_ids)
      }`;

      ApiService.graphql(query, {
        comment_ids: [this.currentComment.id],
        link: this.moveCommentLink,
      })
        .then(({ data }) => {
          if (data.data && data.data.moveComments) {
            this.$toast.success("Chuyển bình luận thành công");
            this.$emit("moveCommentsDone");
            window.$(".moveCommentsClose").click();
            this.loadComments();
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    timeago(x) {
      return timeago(x);
    },
    convertDate(x) {
      return convertDate(x);
    },
    getAllowCommentTime(user) {
      return user && user.allow_comment_until
        ? convertDate(user.allow_comment_until, "DD/MM/YYYY HH:mm")
        : "vô thời hạn";
    },
    getUserLoves(comment) {
      return getUserLoves(comment);
    },
    hasLoved(comment) {
      return hasLoved(comment, this.user);
    },
    canDelete(comment) {
      if (isAdmin()) {
        return true;
      }

      if (
        comment.user &&
        this.user &&
        comment.user.id.toString() === this.user.id.toString()
      ) {
        return true;
      }

      if (
        (isAdminOfEditor() || canManageComment()) &&
        comment.user &&
        !comment.user.prevent_delete_comment
      ) {
        return true;
      }

      return false;
    },
    gotoCurrentComment() {
      if (this.$route.hash && !this.hasGotoCurrentComment) {
        let tmp = this.$route.hash.split("#comment-");
        if (tmp[1]) {
          this.currentCommentId = tmp[1];
          var commentSelector = window.$("#comment-" + this.currentCommentId);
          if (commentSelector && commentSelector.offset()) {
            window.$("html,body").animate(
              {
                scrollTop: commentSelector.offset().top - 100,
              },
              "slow"
            );
            this.hasGotoCurrentComment = true;
          }
        }
      }
    },
    gotoCommentList() {
      window.$("html,body").animate(
        {
          scrollTop: window.$("#comments").offset().top - 80,
        },
        "slow"
      );
      this.needGotoCommentList = false;
    },
    canEditComment(comment) {
      return (
        this.user &&
        comment.user &&
        parseInt(comment.user.id) === parseInt(this.user.id)
      );
    },
    canDeleteComment(comment) {
      return (
        this.user &&
        ((comment.user &&
          parseInt(comment.user.id) === parseInt(this.user.id)) ||
          isAdmin())
      );
    },
    canMarkAsRequest(comment) {
      return (
        this.user &&
        ((comment.user &&
          parseInt(comment.user.id) === parseInt(this.user.id)) ||
          isAdmin())
      );
    },
    canShowPrivateComment() {
      return (
        this.user &&
        ((this.objectUserId &&
          parseInt(this.objectUserId) === parseInt(this.user.id)) ||
          isAdmin())
      );
    },
    canChangeRequestStatus() {
      return isAdmin();
    },
    prepareLoadComments() {
      this.needGotoCommentList = true;
      this.loadComments();
    },
    getRoleClass(alias) {
      return getRoleClass(alias);
    },
    canManageSheet() {
      return canManageSheet();
    },
    canManageSong() {
      return canManageSong();
    },
    canManagePeople() {
      return canManagePeople();
    },
    canManageThumbnail() {
      return canManageThumbnail();
    },
    isAdmin() {
      return isAdmin();
    }
  },
  mounted() {
    this.loadComments();
    this.user = getUser();
  },
  watch: {
    objectId: {
      handler() {
        this.loadComments();
      },
    },
    sort: {
      handler() {
        this.loadComments();
      },
    },
    commentId: {
      handler(newVal) {
        if (newVal) {
          this.sort = "specific";
        }
        this.loadComments();
      },
    },
  },
  computed: {
    disabled() {
      if (this.user && this.user.allow_comment === "N") {
        if (!this.user.allow_comment_until) {
          return true;
        }
        if (
          this.user.allow_comment_until &&
          this.user.allow_comment_until > this.now
        ) {
          return true;
        }
      }

      return false;
    },
    now() {
      console.log(moment().format("YYYY-MM-DD HH:mm:ss"));
      return moment().format("YYYY-MM-DD HH:MM:SS");
    },
  },
};
</script>
