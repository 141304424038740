<template>
  <div class="">
    <div :class="theClass" v-html="contentStripped.slice(0, length) + '... <button class=\'font-semibold\'>Xem thêm</button>'" v-if="!showedMore && contentStripped.length > length" v-on:click="showedMore = true"></div>
    <div :class="theClass" v-html="content" v-if="showedMore || contentStripped.length <= length"></div>
    <div  class="text-right">
      <button v-if="showedMore" v-on:click="showedMore = false" class="font-semibold">Thu gọn</button>
    </div>
  </div>
</template>

<script>
import {stripTags} from "../core/services/utils.service";

export default {
  name: "ShowMore",
  props: {
    length: {
      type: Number,
      default: 200
    },
    class: {
      type: String,
      default: ""
    },
    content: String,
    show: {
      type : Boolean
    }
  },
  data() {
    return {
      showedMore: false,
      theClass: "",
      contentStripped: ""
    }
  },
  created() {
    this.theClass = this.class;
    this.contentStripped = stripTags(this.content);
    this.showedMore = this.show;
  },
  watch: {
    content: {
      handler(newVal) {
        this.contentStripped = stripTags(newVal);
      }
    }
  }
}
</script>
